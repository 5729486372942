
import logo_twitter from 'assets/icons/Home/social/x-twitter-icon.svg';
import logo_facebook from 'assets/icons/Home/social/facebook-icon.svg';
import logo_instagram from 'assets/icons/Home/social/instagram-icon.svg';
import logo from 'assets/icons/Reusable/Logo_SGC.svg';
import LegalFooter from 'Components/Atoms/A_Home/LegalFooter';

const socialMediaInfo = [
   {
      id: 1,
      name: "X",
      icon: logo_twitter,
      url: "https://x.com/sgcol"
   },
   {
      id: 2,
      name: "Instagram",
      icon: logo_instagram,
      url: "https://www.instagram.com/serviciogeologicocolombiano/?hl=es-la"
   },
   {
      id: 3,
      name: "Facebook",
      icon: logo_facebook,
      url: "https://www.facebook.com/sgcolombiano/"
   },
]

function HomeFooter({ hideShadowBox=false }) {

   return (
      <>
         { 
            !hideShadowBox && 
               <div className = "shadowContainer" /> 
         }
         
         <section className = "HomeFooter">
            <div className = "HomeFtContainer">
               <div className = "HomeFtContent">

                  <div className="HomeFtHeader">
                     <p className="HomeFtTitle Title" role="heading" aria-level={6}>
                        Servicio Geológico Colombiano
                     </p>
                     <p className="HomeFtSubTitle Title">
                        Sede Principal
                     </p>
                  </div>

                  <address>
                     <div className="HomeFtInfoGrid" role="grid">

                        <div className="HomeFtDescription First">
                           <p>
                              Dirección: Diagonal 53 N0. 34 - 53 Bogotá D.C. Colombia
                           </p>
                           <p>
                              Código Postal: 111321
                           </p>
                           <p>
                              Horario de Atención Sedes SGC: Lunes a viernes 8.00 a.m. a 5 p.m.
                           </p>
                           <p>
                              Horario de Atención Museo Geológico Nacional:
                              <br />Martes a viernes de 9:00 a.m. a 4:00 p.m. y último sábado de cada mes de 10:00 a.m. a 4:00 p.m.
                           </p>
                           <p>
                              Teléfono conmutador: (601) 220 0200 - (601) 220 0100 - <span className="inline">(601) 222 1811</span>
                           </p>
                        </div>

                        <div className="HomeFtDescription" >
                           <p>
                              Línea anticorrupción y de atención al ciudadano y denuncias:&nbsp; 
                              <span className="inline">01 - 8000 - 110842</span>
                           </p>
                           <p>
                              Línea de atención 24 horas para emergencias radiológicas:&nbsp;
                              <span className="inline">+57 ​317 366 2793</span>
                           </p>
                           <p>
                              Correo Institucional: <span>radicacioncorrespondencia@sgc.gov.co</span>
                           </p>
                           <p className="EmailLarge">
                              Correo de notificaciones judiciales: <span>notificacionesjudiciales@sgc.gov.co</span>
                           </p>
                           <p className="EmailLarge">
                              Correo información relacionada con medios de comunicación: <br /><span>medios@sgc.gov.co</span>
                           </p>
                        </div>

                        <div className="HomeFtSocial">

                           <div className="HomeFtLogo" role="img">
                              <img src={logo} alt="Logo SGC" />
                           </div>

                           <div className="HomeFtSocialMedia">
                              {
                                 socialMediaInfo.map( item => (
                                    <div key={ item.id } className="SocialMediaItem">
                                       <a href={ item.url } title={ item.name }>
                                          <img
                                             className="SocialMediaIcon"
                                             src={ item.icon } 
                                             alt={ item.name }
                                          />
                                          <p className="SocialMediaText">
                                             { item.name }
                                          </p>
                                       </a>
                                    </div>
                                 ))
                              }
                           </div>
                        </div>
                     </div>
                  </address>

                  <div className="HomeFtLinks" role="group">
                     <a href="https://www2.sgc.gov.co/Nosotros/NuestrasSedes/Paginas/nuestras-sedes.aspx">
                        Nuestras sedes
                     </a>
                     <a href="https://www2.sgc.gov.co/Nosotros/AcercaDelSgc/Paginas/Politicas-de-Calidad.aspx">
                        Políticas y cumplimiento legal
                     </a>
                     <a href="https://www2.sgc.gov.co/Paginas/MapaSitio.aspx">
                        Mapa del sitio
                     </a>
                     <a href="https://www2.sgc.gov.co/AtencionAlCiudadano/OfertadeEmpleo/Paginas/convocatorias.aspx">
                        Trabaja con nosotros
                     </a>
                     <a href="https://www2.sgc.gov.co/_layouts/15/Authenticate.aspx?Source=/Paginas/servicio-geologico-colombiano.aspx">
                        Inicio de sesión
                     </a>
                  </div>
                  
               </div>
            </div>
            <LegalFooter />
         </section>
        </>
   )
}
export default HomeFooter;